import Moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import {
  PUT,
  GET,
  POST,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      isUpdateMode: false,
      isDuplicateMode: false,
      quotationProject: null,
      quotationCustomer: 0,
      quotationContactPerson: 0,
      quotationCustomerBilling: 0,
      quotation: 0,
      duplicateQuotation: 0,
      reviseQuotation: 0,
      quotationArr: {},
      pageLoading: true,
      changeAttention: false,
      barcodeDialog: false,
      lineItem: [],
      lineItemCalculation: {},
      termsCondition: null,
      noteAttachment: {},
      customer: {},
      billing: {},
      contact_person: {},
      barcodeSetting: {},
      contactPersonList: new Array(),
      updateData: {
        QuoteLineItem: new Array(),
        QuoteLineItemCalculation: new Object(),
        QuoteTermsConditions: new Object(),
        QuoteNotesAttachment: new Object(),
      },
      quotationCreate: {
        reference: null,
        barcode: null,
        rating: 1,
        job_title: null,
        sales: null,
        prepared_by: null,
        attention: null,
        description: null,
        admin_remark: null,
        client_remark: null,
        term_conditions: null,
        additional_remarks: null,
        discount_value: "0.00",
        adjustment: "0.00",
        discount_type: 1,
        discount_value_type: 0,
        notify_admin: 0,
        notify_customer: 0,
        date: Moment().format("YYYY-MM-DD"),
      },

      customerId: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      customerBillingDialog: false,
    };
  },
  methods: {
    updateContactPerson() {
      const _this = this;
      const contactPerson = _this.lodash.head(
        _this.lodash.filter(_this.contactPersonList, function (row) {
          return row.id === _this.quotationContactPerson;
        })
      );
      if (_this.lodash.isEmpty(contactPerson) === false) {
        _this.contact_person = contactPerson;
        _this.quotationCreate.attention = contactPerson.display_name;
      }
      _this.changeAttention = false;
    },
    loadContentOld() {
      const _this = this;
      if (_this.quotation > 0) {
        _this
          .getDuplicateQuotation(_this.quotation)
          .then((response) => {
            _this.quotationCreate = response;
            _this.updateData.QuoteLineItemCalculation = response;
            _this.updateData.QuoteTermsConditions = response;
            _this.updateData.QuoteNotesAttachment = response;
            _this.updateData.QuoteLineItem = response.line_items;
            _this.$store.dispatch(SET_BREADCRUMB, [
              {
                title: "Quotation",
                route: "quotation",
              },
              {
                title: "Update",
              },
              {
                title: response.barcode,
              },
            ]);
          })
          .catch((error) => {
            _this.logError(error);
            _this.goBack();
          })
          .finally(() => {
            _this.lineItemDialog = false;
          });
      }
    },
    loadContent() {
      const _this = this;
      if (_this.quotation > 0) {
        _this
          .getDuplicateQuotation(_this.quotation)
          .then((response) => {
            if (_this.lodash.isEmpty(response.quotation) === false) {
              _this.$store.dispatch(SET_BREADCRUMB, [
                {
                  title: "Quotation",
                  route: "quotation",
                },
                {
                  title: "Update",
                },
                {
                  title: response.quotation.barcode,
                },
              ]);

              _this.quotationCreate.reference = response.quotation.reference
                ? response.quotation.reference
                : null;
              _this.quotationCreate.barcode = response.quotation.barcode
                ? response.quotation.barcode
                : null;
              _this.quotationCreate.rating = response.quotation.rating
                ? response.quotation.rating
                : 1;
              _this.quotationCreate.job_title = response.quotation.job_title
                ? response.quotation.job_title
                : null;
              _this.quotationCreate.sales = response.quotation.sales
                ? response.quotation.sales
                : null;
              _this.quotationCreate.prepared_by = response.quotation.prepared_by
                ? response.quotation.prepared_by
                : null;
              _this.quotationCreate.attention = response.quotation.attention
                ? response.quotation.attention
                : null;
              _this.quotationCreate.admin_remark = response.quotation
                .admin_remark
                ? response.quotation.admin_remark
                : null;
              _this.quotationCreate.description = response.quotation.description
                ? response.quotation.description
                : null;
              _this.quotationCreate.client_remark = response.quotation
                .client_remark
                ? response.quotation.client_remark
                : null;
              _this.quotationCreate.term_conditions = response.quotation
                .term_conditions
                ? response.quotation.term_conditions
                : null;
              _this.quotationCreate.additional_remarks = response.quotation
                .additional_remarks
                ? response.quotation.additional_remarks
                : null;
              _this.quotationCreate.discount_value = response.quotation
                .discount_value
                ? response.quotation.discount_value
                : "0.00";
              _this.quotationCreate.adjustment = response.quotation.adjustment
                ? response.quotation.adjustment
                : "0.00";
              _this.quotationCreate.discount_type = response.quotation
                .discount_type
                ? response.quotation.discount_type
                : 1;
              _this.quotationCreate.discount_value_type = response.quotation
                .discount_value_type
                ? response.quotation.discount_value_type
                : 0;
              _this.quotationCreate.notify_admin = response.quotation
                .notify_admin
                ? response.quotation.notify_admin
                : 0;
              _this.quotationCreate.notify_customer = response.quotation
                .notify_customer
                ? response.quotation.notify_customer
                : 0;
              _this.quotationCreate.date = Moment().format("YYYY-MM-DD");

              _this.updateData.QuoteLineItemCalculation = response.quotation;
              _this.updateData.QuoteTermsConditions = response.quotation;
              _this.updateData.QuoteNotesAttachment = response.quotation;
              if (
                _this.lodash.isEmpty(response.quotation.line_items) === false &&
                _this.lodash.isArray(response.quotation.line_items)
              ) {
                _this.updateData.QuoteLineItem =
                  response.quotation.line_items.map((row) => {
                    return {
                      id: row.id,
                      original_product: row.original_product,
                      description: row.description,
                      quantity: row.quantity,
                      quantity_type: row.quantity_type,
                      group_me: row.group_me,
                      group_active: row.group_active,
                      group_primary: row.group_primary,
                      tax: row.tax,
                      rate: row.rate,
                      total: row.total,
                      warranty_data:
                        _this.lodash.isEmpty(row.warranty_data) === false
                          ? row.warranty_data.map((row) => {
                              return {
                                id: row.id,
                                days: row.days,
                                field: row.field,
                                value: row.value,
                                warranty: row.warranty,
                              };
                            })
                          : new Array(),
                      warranty_start_date: row.warranty_start_date,
                      warranty_unique_id: row.warranty_unique_id,
                      warranty_description: row.warranty_description,
                      minimum_rental: row.minimum_rental,
                      maximum_rental: row.maximum_rental,
                    };
                  });
              }
            }
            _this.customer = response.customer;
            _this.billing = response.billing;
            _this.contactPersonList = response.contact_person_list;
            _this.contact_person = response.contact_person;
            _this.barcodeSetting = response.option;
          })
          .catch((error) => {
            _this.logError(error);
            _this.goBack();
          })
          .finally(() => {
            _this.pageLoading = false;
            _this.lineItemDialog = false;
          });
      }
    },
    loadDuplicateContent() {
      const _this = this;
      if (_this.duplicateQuotation > 0) {
        _this
          .getDuplicateQuotation(_this.duplicateQuotation)
          .then((response) => {
            if (_this.lodash.isEmpty(response.quotation) === false) {
              _this.quotationCreate = {
                reference: response.quotation.reference
                  ? response.quotation.reference
                  : null,
                rating: response.quotation.rating
                  ? response.quotation.rating
                  : 1,
                job_title: response.quotation.job_title
                  ? response.quotation.job_title
                  : null,
                sales: response.quotation.sales
                  ? response.quotation.sales
                  : null,
                prepared_by: response.quotation.prepared_by
                  ? response.quotation.prepared_by
                  : null,
                attention: response.quotation.attention
                  ? response.quotation.attention
                  : null,
                admin_remark: response.quotation.admin_remark
                  ? response.quotation.admin_remark
                  : null,
                description: response.quotation.description
                  ? response.quotation.description
                  : null,
                client_remark: response.quotation.client_remark
                  ? response.quotation.client_remark
                  : null,
                term_conditions: response.quotation.term_conditions
                  ? response.quotation.term_conditions
                  : null,
                additional_remarks: response.quotation.additional_remarks
                  ? response.quotation.additional_remarks
                  : null,
                discount_value: response.quotation.discount_value
                  ? response.quotation.discount_value
                  : "0.00",
                adjustment: response.quotation.adjustment
                  ? response.quotation.adjustment
                  : "0.00",
                discount_type: response.quotation.discount_type
                  ? response.quotation.discount_type
                  : 1,
                discount_value_type: response.quotation.discount_value_type
                  ? response.quotation.discount_value_type
                  : 0,
                notify_admin: response.quotation.notify_admin
                  ? response.quotation.notify_admin
                  : 0,
                notify_customer: response.quotation.notify_customer
                  ? response.quotation.notify_customer
                  : 0,
                date: Moment().format("YYYY-MM-DD"),
              };
              _this.updateData.QuoteLineItemCalculation = response.quotation;
              _this.updateData.QuoteTermsConditions = response.quotation;
              _this.updateData.QuoteNotesAttachment = response.quotation;
              if (
                _this.lodash.isEmpty(response.quotation.line_items) === false &&
                _this.lodash.isArray(response.quotation.line_items)
              ) {
                _this.updateData.QuoteLineItem =
                  response.quotation.line_items.map((row) => {
                    return {
                      id: null,
                      original_product: row.original_product,
                      description: row.description,
                      quantity: row.quantity,
                      quantity_type: row.quantity_type,
                      group_me: row.group_me,
                      group_active: row.group_active,
                      group_primary: row.group_primary,
                      tax: row.tax,
                      rate: row.rate,
                      total: row.total,
                      warranty_data:
                        _this.lodash.isEmpty(row.warranty_data) === false
                          ? row.warranty_data.map((row) => {
                              return {
                                id: null,
                                days: row.days,
                                field: row.field,
                                value: row.value,
                                warranty: row.warranty,
                              };
                            })
                          : new Array(),
                      warranty_start_date: row.warranty_start_date,
                      warranty_unique_id: row.warranty_unique_id,
                      warranty_description: row.warranty_description,
                      minimum_rental: row.minimum_rental,
                      maximum_rental: row.maximum_rental,
                    };
                  });
              }
            }
            _this.customer = response.customer;
            _this.billing = response.billing;
            _this.contactPersonList = response.contact_person_list;
            _this.contact_person = response.contact_person;
            _this.quotationCreate.barcode = response.barcode;
            _this.barcodeSetting = response.option;
          })
          .catch((error) => {
            _this.logError(error);
            _this.goBack();
          })
          .finally(() => {
            _this.pageLoading = false;
            _this.lineItemDialog = false;
          });
      }
    },
    loadReviseContent() {
      const _this = this;
      if (_this.reviseQuotation > 0) {
        _this
          .getDuplicateQuotation(_this.reviseQuotation)
          .then((response) => {
            if (_this.lodash.isEmpty(response.quotation) === false) {
              _this.quotationCreate = {
                barcode: response.quotation.barcode
                  ? response.quotation.barcode
                  : null,
                reference: response.quotation.reference
                  ? response.quotation.reference
                  : null,
                rating: response.quotation.rating
                  ? response.quotation.rating
                  : 1,
                job_title: response.quotation.job_title
                  ? response.quotation.job_title
                  : null,
                sales: response.quotation.sales
                  ? response.quotation.sales
                  : null,
                prepared_by: response.quotation.prepared_by
                  ? response.quotation.prepared_by
                  : null,
                attention: response.quotation.attention
                  ? response.quotation.attention
                  : null,
                description: response.quotation.description
                  ? response.quotation.description
                  : null,
                admin_remark: response.quotation.admin_remark
                  ? response.quotation.admin_remark
                  : null,
                client_remark: response.quotation.client_remark
                  ? response.quotation.client_remark
                  : null,
                term_conditions: response.quotation.term_conditions
                  ? response.quotation.term_conditions
                  : null,
                additional_remarks: response.quotation.additional_remarks
                  ? response.quotation.additional_remarks
                  : null,
                discount_value: response.quotation.discount_value
                  ? response.quotation.discount_value
                  : "0.00",
                adjustment: response.quotation.adjustment
                  ? response.quotation.adjustment
                  : "0.00",
                discount_type: response.quotation.discount_type
                  ? response.quotation.discount_type
                  : 1,
                discount_value_type: response.quotation.discount_value_type
                  ? response.quotation.discount_value_type
                  : 0,
                notify_admin: response.quotation.notify_admin
                  ? response.quotation.notify_admin
                  : 0,
                notify_customer: response.quotation.notify_customer
                  ? response.quotation.notify_customer
                  : 0,
                date: Moment().format("YYYY-MM-DD"),
              };
              _this.updateData.QuoteLineItemCalculation = response.quotation;
              _this.updateData.QuoteTermsConditions = response.quotation;
              _this.updateData.QuoteNotesAttachment = response.quotation;
              if (
                _this.lodash.isEmpty(response.quotation.line_items) === false &&
                _this.lodash.isArray(response.quotation.line_items)
              ) {
                _this.updateData.QuoteLineItem =
                  response.quotation.line_items.map((row) => {
                    return {
                      id: row.id,
                      original_product: row.original_product,
                      description: row.description,
                      quantity: row.quantity,
                      quantity_type: row.quantity_type,
                      group_me: row.group_me,
                      group_active: row.group_active,
                      group_primary: row.group_primary,
                      tax: row.tax,
                      rate: row.rate,
                      total: row.total,
                      warranty_data:
                        _this.lodash.isEmpty(row.warranty_data) === false
                          ? row.warranty_data.map((row) => {
                              return {
                                id: row.id,
                                days: row.days,
                                field: row.field,
                                value: row.value,
                                warranty: row.warranty,
                              };
                            })
                          : new Array(),
                      warranty_start_date: row.warranty_start_date,
                      warranty_unique_id: row.warranty_unique_id,
                      warranty_description: row.warranty_description,
                      minimum_rental: row.minimum_rental,
                      maximum_rental: row.maximum_rental,
                    };
                  });
              }
            }
            _this.customer = response.customer;
            _this.billing = response.billing;
            _this.contactPersonList = response.contact_person_list;
            _this.contact_person = response.contact_person;
            _this.quotationCreate.barcode = response.barcode;
            _this.barcodeSetting = response.option;

            _this.$store.dispatch(SET_BREADCRUMB, [
              {
                title: "Quotation",
                route: "quotation",
              },
              {
                title: "Revise",
              },
              {
                title: response.barcode,
              },
            ]);
          })
          .catch((error) => {
            _this.logError(error);
            _this.goBack();
          })
          .finally(() => {
            _this.pageLoading = false;
            _this.lineItemDialog = false;
          });
      }
    },
    getDuplicateQuotation(quotation) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "quotation/duplicate/" + quotation,
              data: {
                duplicate: ObjectPath.get(this.$route, "query.duplicate"),
                revise: ObjectPath.get(this.$route, "query.revise"),
                customer: ObjectPath.get(this.$route, "query.customer"),
                billing: ObjectPath.get(this.$route, "query.billing"),
                contact_person: ObjectPath.get(
                  this.$route,
                  "query.contact_person"
                ),
                is_dup_changed: ObjectPath.get(
                  this.$route,
                  "query.isDupChanged"
                ),
              },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getQuotation(quotation) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "quotation/" + quotation,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    updateBarcodeSetting() {
      this.barcodeDialog = false;
    },
    getAttributes() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "quotation/options/" + _this.quotation,
          data: {
            customer: _this.quotationCustomer,
            contact_person: _this.quotationContactPerson,
            billing: _this.quotationCustomerBilling,
          },
        })
        .then((response) => {
          _this.customer = response.data.customer;
          _this.billing = response.data.billing;
          _this.contactPersonList = response.data.contact_person_list;
          _this.contact_person = response.data.contact_person;
          _this.quotationCreate.attention = _this.contact_person.display_name;
          if (!_this.isUpdateMode) {
            _this.quotationCreate.barcode = response.data.barcode;
            _this.barcodeSetting = response.data.option;
            _this.updateData.QuoteTermsConditions = {
              term_conditions: _this.barcodeSetting.terms_conditions,
            };
            _this.updateData.QuoteNotesAttachment = {
              admin_remark: _this.barcodeSetting.admin_note,
              client_remark: _this.barcodeSetting.client_note,
            };
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createLineItems(quotation) {
      return new Promise((resolve, reject) => {
        try {
          const _this = this;
          let revision = false;
          if (_this.reviseQuotation && _this.reviseQuotation > 0) {
            revision = true;
          }
          let line_items = new Array();
          for (let i = 0; i < _this.lineItem.length; i++) {
            line_items.push({
              id: revision ? null : _this.lineItem[i].id,
              group_me: _this.lineItem[i].group_me,
              name: _this.lineItem[i].name,
              group_primary: _this.lineItem[i].group_primary,
              product: _this.lineItem[i].product.id,
              tax: _this.lineItem[i].tax,
              product_type: _this.lineItem[i].product.product_type,
              description: _this.lineItem[i].description,
              quantity: _this.lineItem[i].quantity,
              quantity_type: _this.lineItem[i].quantity_type,
              discount: _this.lineItem[i].discount,
              rate: _this.lineItem[i].rate,
            });
          }

          const formData = {
            quotation,
            line_items,
          };

          _this.formLoading = true;
          _this.$store.dispatch(CLEAR_ERROR, {});
          _this.$store
            .dispatch(POST, {
              url: "line-item",
              data: formData,
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    resetAll() {
      const _this = this;
      if (!_this.isUpdateMode) {
        _this.quotation = 0;
      }
      _this.quotationArr = {};
      _this.pageLoading = true;
      _this.changeAttention = false;
      _this.barcodeDialog = false;
      _this.lineItem = [];
      _this.lineItemCalculation = {};
      _this.termsCondition = null;
      _this.noteAttachment = {};
      _this.customer = {};
      _this.billing = {};
      _this.contact_person = {};
      _this.barcodeSetting = {};
      _this.updateData = {
        QuoteLineItem: new Array(),
        QuoteLineItemCalculation: new Object(),
        QuoteTermsConditions: new Object(),
        QuoteNotesAttachment: new Object(),
      };
      _this.quotationCreate = {
        reference: null,
        barcode: null,
        rating: 1,
        job_title: null,
        sales: null,
        prepared_by: null,
        attention: null,
        description: null,
        admin_remark: null,
        client_remark: null,
        term_conditions: null,
        additional_remarks: null,
        discount_value: "0.00",
        adjustment: "0.00",
        discount_type: 1,
        discount_value_type: 0,
        notify_admin: 0,
        notify_customer: 0,
        date: Moment().format("YYYY-MM-DD"),
      };
    },
    onSubmit(type) {
      const _this = this;

      const validateStatus = _this.$refs.quotationForm.validate();

      const formErrors = _this.validateForm(_this.$refs.quotationForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      let validateLineItem = _this.lodash.compact(
        _this.lodash.map(_this.lineItem, function (row) {
          return row.product;
        })
      );

      if (_this.lodash.isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service/equipment then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (_this.reviseQuotation > 0) {
        type = "revise";
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      const formData = {
        action: typeof type == "string" ? type : null,
        reference: _this.quotationCreate.reference,
        rating: _this.quotationCreate.rating,
        customer: _this.quotationCustomer,
        project: _this.quotationProject || null,
        billing: _this.quotationCustomerBilling,
        contact_person: _this.quotationContactPerson,
        job_title: _this.quotationCreate.job_title,
        sales: _this.quotationCreate.sales,
        prepared_by: _this.quotationCreate.prepared_by,
        attention: _this.quotationCreate.attention,
        description: _this.quotationCreate.description,
        admin_remark: _this.noteAttachment.admin_notes,
        client_remark: _this.noteAttachment.client_notes,
        term_conditions: _this.termsCondition,
        additional_remarks: _this.lineItemCalculation.description,
        discount_value: _this.lineItemCalculation.discountValue,
        tax_value: _this.lineItemCalculation.taxPercentage,
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
        notify_admin: _this.noteAttachment.notify_admin,
        notify_customer: _this.noteAttachment.notify_customer,
        documents: _this.noteAttachment.documents,
        date: _this.quotationCreate.date,
      };
      _this.formLoading = true;

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "quotation";
      if (_this.quotation && _this.quotation > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "quotation/" + _this.quotation;
      }
      if (_this.reviseQuotation && _this.reviseQuotation > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "quotation/" + _this.reviseQuotation;
      }
      _this.$store
        .dispatch(REQUEST_TYPE, {
          url: REQUEST_URL,
          data: formData,
        })
        .then((response) => {
          _this.quotation = _this.lodash.toSafeInteger(response.data.id);
          if (_this.getPermission("line-item:create")) {
            _this
              .createLineItems(_this.quotation)
              .then(() => {
                _this.$router.push(
                  _this.getDefaultRoute("quotation.detail", {
                    params: { id: _this.quotation },
                  })
                );
              })
              .catch((error) => {
                _this.logError(error);
              });
          } else {
            _this.$router.push(
              _this.getDefaultRoute("quotation.detail", {
                params: { id: _this.quotation },
              })
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Quotation",
        route: "quotation",
      },
      {
        title: "Create",
      },
    ]);

    if (_this.isDuplicateMode) {
      _this.loadDuplicateContent();
    } else if (_this.reviseQuotation) {
      _this.loadReviseContent();
    } else {
      _this.getAttributes();
      if (_this.isUpdateMode) {
        _this.loadContent();
      }
    }
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");
    LineItemCalculationEventBus.$off("update:line-item-calculation");
    TermConditionEventBus.$off("update:term-condition");
    NoteAttachmentEventBus.$off("update:notes-attachment");*/
  },
  created() {
    const _this = this;
    _this.quotationProject =
      _this.lodash.toSafeInteger(_this.$route.query.project) || null;

    _this.quotationCustomer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    _this.quotationContactPerson = _this.lodash.toSafeInteger(
      _this.$route.query.contact_person
    );
    _this.quotationCustomerBilling = _this.lodash.toSafeInteger(
      _this.$route.query.billing
    );
    if (
      !_this.quotationCustomer ||
      !_this.quotationContactPerson ||
      !_this.quotationCustomerBilling
    ) {
      _this.$router.go(-1);
    }

    if (
      _this.$route.name == "admin.quotation.update" ||
      _this.$route.name == "engineer.quotation.update"
    ) {
      _this.quotation = _this.lodash.toSafeInteger(_this.$route.params.id);
      if (!_this.quotation) {
        _this.$router.go(-1);
      }
      _this.isUpdateMode = true;
      _this.isDuplicateMode = false;
      _this.resetAll();
    }

    _this.duplicateQuotation = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );

    _this.reviseQuotation = _this.lodash.toSafeInteger(
      _this.$route.query.revise
    );

    if (
      (_this.$route.name == "admin.quotation.create" ||
        _this.$route.name == "engineer.quotation.create") &&
      _this.duplicateQuotation > 0
    ) {
      _this.isDuplicateMode = true;
      _this.isUpdateMode = false;
      _this.resetAll();
    }

    if (
      (_this.$route.name == "admin.quotation.create" ||
        _this.$route.name == "engineer.quotation.create") &&
      _this.reviseQuotation > 0
    ) {
      _this.isDuplicateMode = false;
      _this.isUpdateMode = true;
      _this.resetAll();
    }

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      _this.termsCondition = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.noteAttachment = argument;
    });

    if (_this.lodash.isEmpty(_this.getCurrentUser()) === false) {
      _this.quotationCreate.sales = _this.getCurrentUser().full_name;
      _this.quotationCreate.prepared_by = _this.getCurrentUser().display_name;
    }
  },
  computed: {
    CityName() {
      return this.lodash.isEmpty(this.billing.city) === false
        ? this.billing.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.billing.state) === false
        ? this.billing.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.billing.country) === false
        ? this.billing.country.name
        : null;
    },
  },
};
