<template>
  <div
    class="quotation-create create-form"
    v-if="getPermission('quotation:create')"
  >
    <v-form
      ref="quotationForm"
      v-model="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-skeleton-loader
                    v-if="pageLoading"
                    class="custom-skeleton custom-skeleton-full-width"
                    type="button"
                  ></v-skeleton-loader>
                  <h1 v-else class="form-title d-flex">
                    Quote For
                    <span
                      class="m-0 form-title-create-link pl-2"
                      :class="{
                        'custom-nowrap-ellipsis': !lodash.isEmpty(customer),
                      }"
                      link
                    >
                      <template v-if="lodash.isEmpty(customer)"
                        >Client Name</template
                      >
                      <template v-else>{{ customer.display_name }}</template>
                      <v-icon
                        link
                        large
                        color="cyan"
                        class="mx-2"
                        v-on:click="customerDialog = true"
                        v-if="lodash.isEmpty(customer) || isDuplicateMode"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </span>
                  </h1>
                </v-col>
                <v-col cols="5" class="pt-0 text-right">
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading || pageLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!formValid || formLoading || pageLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="onSubmit"
                    color="cyan"
                  >
                    Save Quote
                  </v-btn>
                  <template v-if="false">
                    <v-menu
                      v-if="!isUpdateMode"
                      content-class="custom-menu-list"
                      transition="slide-y-transition"
                      bottom
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="!formValid || formLoading || pageLoading"
                          :loading="formLoading"
                          class="mx-2 custom-bold-button white--text"
                          color="cyan"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Save and... <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          link
                          v-on:click="onSubmit('send_as_email')"
                        >
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >Send as Email</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          link
                          v-on:click="onSubmit('mark_as_approved')"
                        >
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >Mark as Approved</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          link
                          v-on:click="onSubmit('mark_as_pending')"
                        >
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >Mark as Pending</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-col>
              </v-row>
            </div>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <div class="p-5 pt-0">
              <v-row>
                <v-col cols="7" class="pb-0">
                  <v-container fluid>
                    <div>
                      <label class="font-weight-700 font-size-16"
                        >Job Title</label
                      >
                      <v-text-field
                        v-model="quotationCreate.job_title"
                        dense
                        filled
                        color="cyan"
                        label="Title"
                        :disabled="isPageLoading"
                        solo
                        flat
                        :rules="[
                          validateRules.required(
                            quotationCreate.job_title,
                            'Job Title'
                          ),
                          validateRules.minLength(
                            quotationCreate.job_title,
                            'Job Title',
                            1
                          ),
                          validateRules.maxLength(
                            quotationCreate.job_title,
                            'Job Title',
                            100
                          ),
                        ]"
                      ></v-text-field>
                      <TinyMCE v-model="quotationCreate.description" />
                    </div>
                    <div>
                      <v-row>
                        <v-col cols="6">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@5"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Company address</label
                            >
                            <div v-if="lodash.isEmpty(billing) === false">
                              <p
                                v-if="billing.property_address"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ billing.property_address }}
                              </p>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="6">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@3"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Contact details</label
                            >
                            <div
                              v-if="lodash.isEmpty(contact_person) === false"
                            >
                              <p
                                v-if="contact_person.display_name"
                                class="mb-0 font-size-16"
                              >
                                <template
                                  v-if="
                                    changeAttention ||
                                    !quotationCreate.attention
                                  "
                                >
                                  <v-autocomplete
                                    v-model="quotationContactPerson"
                                    :items="contactPersonList"
                                    dense
                                    filled
                                    :disabled="isPageLoading"
                                    color="cyan"
                                    item-color="cyan"
                                    item-text="display_name"
                                    item-value="id"
                                    label="Job Type"
                                    solo
                                    flat
                                    hide-details
                                    @keydown.esc="changeAttention = false"
                                    @keydown.enter="changeAttention = false"
                                    @keydown.tab="changeAttention = false"
                                    v-on:change="updateContactPerson"
                                    :rules="[
                                      validateRules.required(
                                        quotationContactPerson,
                                        'Contact Person'
                                      ),
                                    ]"
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            v-html="'No Contact Person Found.'"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-autocomplete>
                                </template>
                                <template v-else>
                                  <div class="d-flex">
                                    <p class="mb-0 text-ellipsis width-300px">
                                      {{ quotationCreate.attention }}
                                    </p>
                                    <v-btn
                                      text
                                      small
                                      :disabled="isPageLoading"
                                      class="custom-bold-button"
                                      v-on:click="changeAttention = true"
                                      color="cyan"
                                    >
                                      Change
                                    </v-btn>
                                  </div>
                                </template>
                              </p>
                              <p
                                v-if="contact_person.primary_phone"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ contact_person.primary_phone }}
                              </p>
                              <p
                                v-if="contact_person.primary_email"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ contact_person.primary_email }}
                              </p>
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                </v-col>
                <v-col cols="5" class="pb-0">
                  <v-container fluid>
                    <table class="width-100">
                      <tr>
                        <td
                          colspan="3"
                          class="font-weight-700 font-size-16 pb-2"
                        >
                          Quote details
                        </td>
                      </tr>
                      <tr v-if="reviseQuotation === 0">
                        <td class="font-size-16 pb-2" width="200">
                          Quote number
                        </td>
                        <td
                          class="font-weight-700 font-size-16 pb-2"
                          width="150"
                          :colspan="isUpdateMode ? 2 : 1"
                        >
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton"
                            type="text"
                          ></v-skeleton-loader>
                          <template v-else>{{
                            quotationCreate.barcode
                          }}</template>
                        </td>
                        <td v-if="!isUpdateMode" class="font-size-16 pb-2">
                          <v-btn
                            text
                            small
                            :disabled="isPageLoading"
                            class="custom-bold-button"
                            v-on:click="barcodeDialog = true"
                            color="cyan"
                          >
                            Change
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-2 font-size-16">Rate opportunity</td>
                        <td colspan="2" class="pb-2">
                          <v-rating
                            :readonly="isPageLoading"
                            v-model="quotationCreate.rating"
                            background-color="orange lighten-3"
                            color="orange"
                          ></v-rating>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" class="pb-4">
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="isPageLoading"
                            v-model="quotationCreate.reference"
                            label="Reference #"
                            :rules="[
                              validateRules.minLength(
                                quotationCreate.reference,
                                'Reference',
                                1
                              ),
                              validateRules.maxLength(
                                quotationCreate.reference,
                                'Reference',
                                100
                              ),
                            ]"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td colspan="3" class="pb-4">
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="isPageLoading"
                            :rules="[
                              validateRules.minLength(
                                quotationCreate.sales,
                                'Sales representative',
                                1
                              ),
                              validateRules.maxLength(
                                quotationCreate.sales,
                                'Sales representative',
                                100
                              ),
                            ]"
                            v-model="quotationCreate.sales"
                            label="Sales representative"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" class="pb-4">
                          <v-text-field
                            dense
                            filled
                            color="cyan"
                            :disabled="isPageLoading"
                            :rules="[
                              validateRules.minLength(
                                quotationCreate.prepared_by,
                                'Prepared by',
                                1
                              ),
                              validateRules.maxLength(
                                quotationCreate.prepared_by,
                                'Prepared by',
                                100
                              ),
                            ]"
                            v-model="quotationCreate.prepared_by"
                            label="Prepared by"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                    </table>
                  </v-container>
                </v-col>
                <v-col
                  cols="12"
                  class="pt-0"
                  v-if="getPermission('line-item:create')"
                >
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Line Items
                        </h3>
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <v-row dense>
                          <v-col cols="12" class="pt-0 pb-0">
                            <QuoteLineItem
                              isQuotation
                              :updateData.sync="updateData.QuoteLineItem"
                              :pageLoading.sync="isPageLoading"
                            ></QuoteLineItem>
                          </v-col>
                          <v-col cols="12" class="pt-0">
                            <QuoteLineItemCalculation
                              isQuotation
                              :updateData.sync="updateData.QuoteLineItemCalculation"
                              :pageLoading.sync="isPageLoading"
                            ></QuoteLineItemCalculation>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col cols="12">
                  <QuoteTermsConditions
                    :updateData.sync="updateData.QuoteTermsConditions"
                    :pageLoading.sync="isPageLoading"
                  ></QuoteTermsConditions>
                </v-col>
                <v-col cols="12">
                  <QuoteNotesAttachment
                    :updateData.sync="updateData.QuoteNotesAttachment"
                    :pageLoading.sync="isPageLoading"
                  ></QuoteNotesAttachment>
                </v-col>
              </v-row>
            </div>
            <!-- </perfect-scrollbar> -->
          </v-container>
        </v-col>
      </v-row>
    </v-form>
    <template v-if="customerDialog">
      <CustomerDialog
        isQuotation
        :customerDialog.sync="customerDialog"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomer="selectCustomer"
        v-on:selectCustomerPerson="selectCustomerPerson"
        v-on:selectCustomerBilling="selectCustomerBilling"
      ></CustomerDialog>
    </template>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        :customerPersonDialog.sync="customerPersonDialog"
        :customer.sync="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
    <BarcodeSetting
      endpoint="quotation"
      :barcodeDialog="barcodeDialog"
      :dialogWidth="600"
      :barcodeSetting.sync="barcodeSetting"
      v-on:close:barcode-dialog="barcodeDialog = false"
    ></BarcodeSetting>
  </div>
</template>

<script>
import CreateQuotationMixin from "@/core/lib/quotation/create.quotation.mixin";
import QuoteLineItem from "@/view/pages/partials/Line-Item.vue";
import QuoteLineItemCalculation from "@/view/pages/partials/Line-Item-Calculation.vue";
import QuoteTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import QuoteNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import ObjectPath from "object-path";

export default {
  mixins: [CreateQuotationMixin],
  name: "quotation-create",
  title: "Create Quotation",
  methods: {
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.resetAll();
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerBilling || this.customerBilling <= 0) {
        this.resetAll();
      }
    },
    selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkQuotation();
    },
    checkQuotation() {
      const _this = this;
      if (
        _this.customer > 0 &&
        _this.customerPerson > 0 &&
        _this.customerBilling > 0
      ) {
        /* _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: {
              customer: _this.customer,
              contact_person: _this.customerPerson,
              billing: _this.customerBilling,
              duplicate: ObjectPath.get(this.$route, "query.duplicate"),
            }
          })
        ); */

        let route = this.getDefaultRoute("quotation.create", {
          query: {
            customer: this.customer,
            contact_person: this.customerPerson,
            billing: _this.customerBilling,
            duplicate: ObjectPath.get(this.$route, "query.duplicate"),
            isDupChanged: 1,
          },
        });
        const resolved = this.$router.resolve(route);
        window.location.href = resolved.href;
      }
    },
  },
  components: {
    QuoteLineItem,
    QuoteLineItemCalculation,
    QuoteTermsConditions,
    QuoteNotesAttachment,
    BarcodeSetting,
    TinyMCE,
    CustomerDialog,
    CustomerPersonDialog,
  },
  computed: {
    isPageLoading() {
      return this.pageLoading || this.formLoading;
    },
  },
};
</script>
